import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
  faCog,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
  InputGroup,
  Form,
} from "@themesberg/react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useRxData, useRxCollection } from "rxdb-hooks";
import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import commands from "../data/commands";
import * as DateFNS from "date-fns";

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon
            icon={bounceIcon}
            className={`${bounceTxtColor} me-3`}
          />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">
              See all
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map((pv) => (
            <TableRow key={`page-visit-${pv.id}`} {...pv} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const {
      id,
      source,
      sourceIcon,
      sourceIconColor,
      sourceType,
      category,
      rank,
      trafficShare,
      change,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon
            icon={sourceIcon}
            className={`icon icon-xs text-${sourceIconColor} w-30`}
          />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0"
                now={trafficShare}
                min={0}
                max={100}
              />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : "-"}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = () => {
  const metadata = {
    keyField: "invoiceNumber",
    columns: [
      {
        header: "#",
        field: "invoiceNumber",
        linkFunc: (x) => Routes.EditPDFForm.path.replace(":form_id", x.id),
      },
      { header: "Bill For", field: "subscription" },
      { header: "Issue Date", field: "issueDate" },
      { header: "Due Date", field: "dueDate" },
      {
        header: "Total",
        field: "price",
        formatFunc: (x) => "$" + parseFloat(x).toFixed(2),
      },
      {
        header: "Status",
        field: "status",
        classNameFunc: (x) =>
          "text-" +
          (x === "Paid"
            ? "success"
            : x === "Due"
            ? "warning"
            : x === "Canceled"
            ? "danger"
            : "primary"),
      },
    ],
    actions: [
      { title: "View Details", icon: faEye },
      { title: "Edit", icon: faEdit },
      { title: "Remove", icon: faTrashAlt, className: "text-danger" },
    ],
  };

  return <ValtioTable metadata={metadata} collection="transactions" />;

  // const TableRow = (props) => {
  //   const { transaction } = props;
  //   const statusVariant = transaction.status === "Paid" ? "success"
  //     : transaction.status === "Due" ? "warning"
  //       : transaction.status === "Canceled" ? "danger" : "primary";
  //
  //   return (
  //     <tr>
  //       <td>
  //         <Card.Link as={Link} to={Routes.EditPDFForm.path.replace(':form_id', transaction.invoiceNumber)} className="fw-normal">
  //           {transaction.invoiceNumber}
  //         </Card.Link>
  //       </td>
  //       <td>
  //         <span className="fw-normal">
  //           {transaction.subscription}
  //         </span>
  //       </td>
  //       <td>
  //         <span className="fw-normal">
  //           {transaction.issueDate}
  //         </span>
  //       </td>
  //       <td>
  //         <span className="fw-normal">
  //           {transaction.dueDate}
  //         </span>
  //       </td>
  //       <td>
  //         <span className="fw-normal">
  //           ${parseFloat(transaction.price).toFixed(2)}
  //         </span>
  //       </td>
  //       <td>
  //         <span className={`fw-normal text-${statusVariant}`}>
  //           {transaction.}
  //         </span>
  //       </td>
  //       <td>
  //         <Dropdown as={ButtonGroup}>
  //           <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
  //             <span className="icon icon-sm">
  //               <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
  //             </span>
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu>
  //             <Dropdown.Item>
  //               <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
  //             </Dropdown.Item>
  //             <Dropdown.Item>
  //               <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
  //             </Dropdown.Item>
  //             <Dropdown.Item className="text-danger">
  //               <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
  //             </Dropdown.Item>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </td>
  //     </tr>
  //   );
  // };

  // if (isFetching) {
  //   return 'Loading...';
  // }

  // return (
  //   <Card border="light" className="table-wrapper table-responsive shadow-sm">
  //     <Card.Body className="pt-0">
  //       <Table hover className="user-table align-items-center">
  //         <thead>
  //           <tr>
  //             <th className="border-bottom">#</th>
  //             <th className="border-bottom">Bill For</th>
  //             <th className="border-bottom">Issue Date</th>
  //             <th className="border-bottom">Due Date</th>
  //             <th className="border-bottom">Total</th>
  //             <th className="border-bottom">Status</th>
  //             <th className="border-bottom">Action</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {transactions.map(t => <TableRow key={`transaction-${t.invoiceNumber}`} transaction={t} />)}
  //         </tbody>
  //       </Table>
  //       <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
  //         <Nav>
  //           <Pagination className="mb-2 mb-lg-0">
  //             {currentPage > 1 &&
  //             <Pagination.Prev onClick={() => fetchPage(currentPage - 1)}>
  //               Previous
  //             </Pagination.Prev>
  //             }
  //             {pageCount > 1 && Array(pageCount).fill().map((x, i) => (
  //               <Pagination.Item active={currentPage === i + 1} onClick={() => fetchPage(i + 1)}>{i + 1}</Pagination.Item>
  //             ))}
  //             {currentPage < pageCount &&
  //             <Pagination.Next onClick={() => fetchPage(currentPage + 1)}>
  //               Next
  //             </Pagination.Next>
  //             }
  //           </Pagination>
  //         </Nav>
  //         <small className="fw-bold">
  //           Showing <b>{totalTransactions}</b> out of <b>25</b> entries
  //         </small>
  //       </Card.Footer>
  //     </Card.Body>
  //   </Card>
  // );
};

export const FormsTable = () => {
  const history = useHistory();
  const collection = useRxCollection("forms");
  const metadata = {
    keyField: "id",
    columns: [
      {
        header: "Name",
        field: "name",
        linkFunc: (x) => Routes.EditPDFForm.path.replace(":form_id", x.id),
      },
      { header: "Organization", field: "organization_name" },
      {
        header: "Delegated?",
        field: "delegated",
        formatFunc: (x) =>
          x && <FontAwesomeIcon icon={faCheck} className="me-2" />,
      },
      {
        header: "Allow Existing?",
        field: "allow_existing",
        formatFunc: (x) =>
          x && <FontAwesomeIcon icon={faCheck} className="me-2" />,
      },
      {
        header: "Created On",
        field: "created_date",
        formatFunc: (x) => {
          return x
            ? DateFNS.lightFormat(DateFNS.parseISO(x), "MM/dd/yyyy h:mm a")
            : "";
        },
      },
    ],
    actions: [
      {
        title: "Edit",
        icon: faEdit,
        onClick: (x) =>
          history.push(Routes.EditPDFForm.path.replace(":form_id", x.id)),
      },
      {
        title: "Remove",
        icon: faTrashAlt,
        className: "text-danger",
        onClick: async (x) => {
          if (
            window.confirm(`Are you sure you want to delete form '${x.name}'?`)
          )
            (await collection.findOne(x.id)).remove();
        },
      },
    ],
  };

  return <ValtioTable metadata={metadata} collection="forms" />;
};

export const ValtioTable = (props) => {
  const { metadata, collection } = props;
  const [search, setSearch] = React.useState();
  const [pageSize, setPageSize] = React.useState(10);
  const {
    result: documents,
    isFetching,
    fetchPage,
    currentPage,
    pageCount,
  } = useRxData(
    collection,
    (collection) => {
      if (search)
        return collection.find({
          selector: {
            name: { $regex: `.*${search}.*`, $options: "i" },
          },
        });
      else return collection.find();
    },
    {
      pageSize: pageSize,
      pagination: "Traditional",
    }
  );
  const rxCollection = useRxCollection(collection);
  const [totalDocumentCount, setTotalDocumentCount] = React.useState();
  const currentPageDocumentCount = documents ? documents.length : 0;

  React.useEffect(() => {
    async function load() {
      rxCollection && setTotalDocumentCount(await rxCollection.count().exec());
    }

    load().then();
  }, [rxCollection, documents]);

  const onSearch = React.useCallback(({ target: { value } }) => {
    setSearch(value);
  }, []);

  const TableRow = (props) => {
    const { document } = props;
    if (isFetching) {
      return "Loading...";
    }
    return (
      <tr>
        {metadata.columns.map((c, i) => (
          <td key={`c-${document[metadata.keyField]}-${i}`}>
            {c.link || c.linkFunc ? (
              <Card.Link
                as={Link}
                to={c.linkFunc ? c.linkFunc(document) : c.link}
                className={
                  "fw-normal " +
                  (c.classNameFunc
                    ? c.classNameFunc(document[c.field])
                    : c.className)
                }
              >
                {c.formatFunc
                  ? c.formatFunc(document[c.field])
                  : document[c.field]}
              </Card.Link>
            ) : (
              <span
                className={
                  "fw-normal " +
                  (c.classNameFunc
                    ? c.classNameFunc(document[c.field])
                    : c.className)
                }
              >
                {c.formatFunc
                  ? c.formatFunc(document[c.field])
                  : document[c.field]}
              </span>
            )}
          </td>
        ))}
        {metadata.actions && (
          <td>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                as={Button}
                split
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {metadata.actions.map((a, i) => (
                  <Dropdown.Item
                    className={a.className}
                    key={`a-${document[metadata.keyField]}-${i}`}
                    onClick={() => a.onClick(document)}
                  >
                    <FontAwesomeIcon icon={a.icon} className="me-2" /> {a.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        )}
      </tr>
    );
  };

  return (
    <>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                key="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={onSearch}
                autoFocus="autoFocus"
              />
            </InputGroup>
          </Col>
          <Col
            xs={4}
            md={2}
            xl={1}
            className="ps-md-0 text-end d-flex justify-content-end"
          >
            <ButtonGroup>
              <Dropdown>
                <Dropdown.Toggle
                  split
                  as={Button}
                  variant="link"
                  className="text-dark m-0 p-0"
                >
                  <span className="icon icon-sm icon-gray">
                    <FontAwesomeIcon icon={faCog} />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-center">
                  <Dropdown.Item className="fw-bold text-dark">
                    Show
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex fw-bold"
                    onClick={() => setPageSize(10)}
                  >
                    10
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => setPageSize(20)}
                  >
                    20
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => setPageSize(20)}
                  >
                    30
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                {metadata.columns.map((c, i) => (
                  <th className="border-bottom" key={`c-${i}`}>
                    {c.header}
                  </th>
                ))}
                {metadata.actions && <th className="border-bottom">Action</th>}
              </tr>
            </thead>
            <tbody>
              {documents.map((document) => (
                <TableRow
                  key={`document-${document[metadata.keyField]}`}
                  document={document}
                />
              ))}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                {currentPage > 1 && (
                  <Pagination.Prev onClick={() => fetchPage(currentPage - 1)}>
                    Previous
                  </Pagination.Prev>
                )}
                {pageCount > 1 &&
                  Array(pageCount)
                    .fill()
                    .map((x, i) => (
                      <Pagination.Item
                        key={i}
                        active={currentPage === i + 1}
                        onClick={() => fetchPage(i + 1)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    ))}
                {currentPage < pageCount && (
                  <Pagination.Next onClick={() => fetchPage(currentPage + 1)}>
                    Next
                  </Pagination.Next>
                )}
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{currentPageDocumentCount}</b> out of{" "}
              <b>{totalDocumentCount}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: "5%" }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: "5%" }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: "50%" }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: "40%" }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{" "}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: "5%" }}>
                Name
              </th>
              <th className="border-0" style={{ width: "5%" }}>
                Usage
              </th>
              <th className="border-0" style={{ width: "50%" }}>
                Description
              </th>
              <th className="border-0" style={{ width: "40%" }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
