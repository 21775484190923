import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faHome } from "@fortawesome/free-solid-svg-icons";
import { Nav, Button, Breadcrumb } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { FormsTable } from "../../components/Tables";
import { Routes } from "../../routes";

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Valtio</Breadcrumb.Item>
            <Breadcrumb.Item active>Forms</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Forms</h4>
          <p className="mb-0">Forms available on the Valtio platform.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Nav.Link as={Link} to={Routes.CreatePDFForm.path}>
            <Button variant="secondary" className="m-1">
              <FontAwesomeIcon icon={faPlus} className="me-2" /> New
            </Button>
          </Nav.Link>
        </div>
        {/*<div className="btn-toolbar mb-2 mb-md-0">*/}
        {/*  <ButtonGroup>*/}
        {/*    <Button variant="outline-primary" size="sm">Share</Button>*/}
        {/*    <Button variant="outline-primary" size="sm">Export</Button>*/}
        {/*  </ButtonGroup>*/}
        {/*</div>*/}
      </div>

      <FormsTable />
    </>
  );
};
