import React, { PropsWithChildren } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";

export function AuthWrapper({
  db,
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const auth = useAuth();

  // automatically sign-in
  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !auth.isAuthenticated
    ) {
      auth.signinRedirect();
    }
  }, [auth]);

  if (auth.activeNavigator === "signinSilent") {
    return <div>Signing you in...</div>;
  }

  if (auth.activeNavigator === "signoutRedirect") {
    return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    return <div>Not logged in.</div>;
  }

  return <>{children}</>;
}
