import { addRxPlugin, createRxDatabase } from "rxdb";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { RxDBCleanupPlugin } from "rxdb/plugins/cleanup";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBFlexSearchPlugin } from "rxdb-flexsearch";

addRxPlugin(RxDBCleanupPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBFlexSearchPlugin);

export const schemas = {
  transactions: {
    schema: {
      version: 0,
      primaryKey: "invoiceNumber",
      type: "object",
      properties: {
        invoiceNumber: {
          type: "string",
          maxLength: 100, // <- the primary key must have set maxLength
        },
        status: {
          type: "string",
        },
        subscription: {
          type: "string",
        },
        price: {
          type: "string",
        },
        issueDate: {
          type: "string",
          format: "date-time",
        },
        dueDate: {
          type: "string",
          format: "date-time",
        },
      },
      required: [
        "invoiceNumber",
        "status",
        "subscription",
        "price",
        "issueDate",
        "dueDate",
      ],
    },
  },
  forms: {
    schema: {
      version: 0,
      primaryKey: "id",
      type: "object",
      properties: {
        id: {
          type: "string",
          maxLength: 37, // <- the primary key must have set maxLength
        },
        name: {
          type: "string",
        },
        description: {
          type: "string",
        },
        delegated: {
          type: "boolean",
        },
        allow_existing: {
          type: "boolean",
        },
        created_date: {
          type: "string",
          format: "date-time",
        },
        updated_date: {
          type: "string",
          format: "date-time",
        },
        template_id: {
          type: "string",
          maxLength: 32,
        },
        organization_id: {
          type: "string",
          maxLength: 32,
        },
        organization_name: {
          type: "string",
        },
      },
      required: [
        "id",
        "name",
        "description",
        "delegated",
        "allow_existing",
        "created_date",
        "updated_date",
        "template_id",
        "organization_id",
      ],
    },
    options: {
      searchable: true,
    },
  },
};

export const initialize = async () => {
  const db = await createRxDatabase({
    name: "valtio-admin",
    storage: getRxStorageDexie(),
    ignoreDuplicate: true,
  });

  db.$.subscribe((changeEvent) => {
    console.log(changeEvent);
  });

  const collections = await db.addCollections(schemas);
  // await collections.transactions.cleanup(0);
  // await collections.forms.cleanup(0);
  // await collections.transactions.bulkInsert(transactions)

  return db;
};
