import React from "react";
import { useAuth } from "react-oidc-context";
import { DocusealBuilder } from "@docuseal/react";
import Config from "../config";

export default (props) => {
  const { form } = props;
  const auth = useAuth();
  const [token, setToken] = React.useState();

  React.useEffect(() => {
    async function load() {
      const response = await fetch(
        `${Config.valtioAPIEndpoint}/v1/forms/${form.id}/esignature_editor_token`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        }
      );
      setToken(await response.json());
    }

    form && load().then();
  }, [auth, form]);

  if (!token || !form) {
    return "loading...";
  }

  return (
    <>
      {
        <DocusealBuilder
          token={token}
          withRecipientsButton={false}
          withSignYourselfButton={false}
          withUploadButton={false}
          autosave={true}
        />
      }
    </>
  );
};
