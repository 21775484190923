import * as Yup from "yup";

export const CHECKBOX = Yup.boolean();

export const SELECT = Yup.string().matches(/^[^_]*$/, "Please select a option");

export const FIRST_NAME = Yup.string()
  .matches(/^[A-Za-z]/, "Name can only start with a letter")
  .matches(/[^\s]*/, "Please enter only your first name")
  .matches(
    /^[A-Za-z'-]*$/,
    "Name can only contain letters, apostrophes, and hyphens"
  );

export const LAST_NAME = Yup.string()
  .matches(/^[A-Za-z]/, "Name can only start with a letter")
  .matches(
    /^[A-Za-z'\-\s]*$/,
    "Name can only contain letters, apostrophes, hyphens and spaces"
  );

export const FULL_NAME = Yup.string()
  .matches(/^[A-Za-z]/, "Name can only start with a letter")
  .matches(
    /^[A-Za-z'\.\-\s]*$/,
    "Name can only contain letters, apostrophes, hyphens and spaces"
  )
  .matches(/\s+/, "Please enter both first and last name");

export const ALPHANUMERIC_NAME = Yup.string()
  .matches(/^[A-Za-z]/, "Name can only start with a letter")
  .matches(
    /^[A-Za-z0-9'\.\-\s]*$/,
    "Name can only contain letters, numbers, apostrophes, hyphens and spaces"
  );

export const DESCRIPTION = Yup.string();

export const DATE = Yup.date();

export const EMAIL = Yup.string().email("Please enter a valid email address");

export const US_PHONE_NUMBER = Yup.string().matches(
  /^(\+1 )?(\([0-9]{3}\) ?|[0-9]{3}-?)[0-9]{3}-?[0-9]{4}$/,
  "Please enter a valid U.S. phone number"
);

export const US_ADDRESS = Yup.string();

export const OTP = Yup.string()
  .matches(/^[0-9]*$/, "Code can only contain digits")
  .length(6, "Code is ${length} digits long")
  .trim();

export const PIN = Yup.string()
  .matches(/^[0-9]*$/, "Pin can only contain digits")
  .length(6, "Pin must be ${length} digits long")
  .trim();

export const UUID = Yup.string()
  .matches(/^[a-z0-9]{32}$/, "UUID must be 32 chars long")
  .length(32, "UUID must be ${length} chars long")
  .trim();

export const VAULT_ITEM_NAME = Yup.string()
  .matches(
    /^[0-9A-Za-z'_\-\s]*$/,
    "Filename can only contain alphanumeric characters, underscores, apostrophes, hyphens and spaces"
  )
  .trim();

export const FILE = (extensions, max_file_size) =>
  Yup.mixed()
    .test({
      message: "Please provide a supported file type",
      test: (file, context) => {
        const isValid = extensions.includes(
          file?.name.split(".").pop().lower()
        );
        if (!isValid) context?.createError();
        return isValid;
      },
    })
    .test({
      message: `File too big, can't exceed ${max_file_size}`,
      test: (file) => {
        return file?.size < max_file_size;
      },
    });
