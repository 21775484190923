import React from "react";

async function uploadFile(file, uri, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const pct = e.loaded / e.total;
        onProgress(pct * 100);
      }
    });
    xhr.upload.addEventListener("error", (e) => {
      reject(new Error("Upload failed: " + e.toString()));
    });
    xhr.upload.addEventListener("abort", (e) => {
      reject(new Error("Upload aborted: " + e.toString()));
    });
    xhr.addEventListener("load", (e) => {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject(new Error("Upload failed " + xhr.status + " " + e.toString()));
      }
    });
    xhr.open("PUT", uri, true);
    try {
      xhr.send(file);
    } catch (e) {
      reject(new Error("Upload failed: " + e.toString()));
    }
  });
}

export function useUpload() {
  const [uploadState, setUploadState] = React.useState("idle");
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadError, setUploadError] = React.useState(null);

  return {
    uploadState,
    uploadProgress,
    uploadError,
    upload: async (file, uri, onSuccess = null) => {
      setUploadState("starting");

      try {
        setUploadState("uploading");
        // Actually upload using XmlHttpRequest:
        await uploadFile(file, uri, (pct) => {
          setUploadProgress(pct);
        });
        setUploadState("finishing");
        // Do something useful with this uploaded file; probably pass
        // this key to another API endpoint!
        onSuccess && (await onSuccess());
        setUploadState("done");
      } catch (e) {
        setUploadState("error");
        setUploadError(e);
      }
    },
  };
}
