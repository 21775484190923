import React from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Form, Row } from "@themesberg/react-bootstrap";

export default (props) => {
  const { onDrop = null, onCancel = null } = props;
  const [file, setFile] = React.useState([]);
  const [preview, setPreview] = React.useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ["application/pdf"],
    maxFiles: 1,
    multiple: false,
    onDrop: (files) => {
      const file = files[0];
      setFile(file);
      setPreview(URL.createObjectURL(files[0]));
      onDrop && onDrop(file);
    },
  });

  const DropzoneFile = (props) => {
    const { path, preview } = props;

    return (
      <Col xs={3} className="dropzone-preview">
        <embed src={preview} className="dropzone-image" />
        {/*<Image src={preview} className="dropzone-image" />*/}
        <Card.Text className="dropzone-filename">{path}</Card.Text>
      </Col>
    );
  };

  return (
    <>
      <Form.Group
        {...getRootProps({
          className:
            "d-flex dropzone rounded align-items-center justify-content-center",
        })}
      >
        <Form.Control {...getInputProps()} />
        <div className="dz-default dz-message text-center">
          <p className="dz-button mb-0">
            {isDragActive
              ? "Drop file here to upload"
              : "Drag file here OR click to select files"}
          </p>
        </div>
      </Form.Group>
      {onCancel && (
        <Button variant="danger" className="my-2 w-100" onClick={onCancel}>
          <FontAwesomeIcon icon={faWindowClose} /> Cancel
        </Button>
      )}
      {/*<Form.Group>*/}
      {/*  <Row className="dropzone-files">*/}
      {/*    <DropzoneFile path={file.path} preview={preview} />*/}
      {/*  </Row>*/}
      {/*</Form.Group>*/}
    </>
  );
};
