import { WebStorageStateStore } from "oidc-client-ts";

const Config = {
  envName: process.env.REACT_APP_ENV_NAME,
  valtioAPIEndpoint: "",
  valtioOrganizationId: "",
  graphql: {
    endpoint: "",
    region: "",
    apiKey: "",
    channel: "Admin"
  },
  oidc: {
    authority:
      "https://login.microsoftonline.com/723ae799-53a3-472c-9698-f823588f8972/v2.0/",
    client_id: "bc09745b-7a91-4657-9d3a-30bbde0b2cf0",
    scope: "bc09745b-7a91-4657-9d3a-30bbde0b2cf0/.default",
    redirect_uri: "",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
  },
  debug: false,
};

if (process.env.REACT_APP_ENV_NAME === 'production') {
  Config.valtioAPIEndpoint = 'https://api.valtio.io'
  Config.valtioOrganizationId = "a2914994b4314bc994d237df933121a3"
  Config.graphql = {
    endpoint: "https://2ymltltv2fhytc7wwckreyjcje.appsync-api.us-east-2.amazonaws.com/graphql",
    region: "us-east-2",
    apiKey: "da2-elzlm2f2aram7k7zicxqq2kfpu",
    channel: "Admin"
  }
  Config.oidc.redirect_uri = "https://admin.valtio.io"
} else if (process.env.REACT_APP_ENV_NAME === 'integration') {
  Config.valtioAPIEndpoint = 'https://api-integration.valtio.io'
  Config.valtioOrganizationId = "71cc11ddc66e4eff84db1cfc3b6146ee"
  Config.graphql = {
    endpoint: "https://aezkbbcuhbddxdnqqcbrnsbaee.appsync-api.us-east-2.amazonaws.com/graphql",
    region: "us-east-2",
    apiKey: "da2-j5dgl7aekjei7len22vg7dct5q",
    channel: "Admin"
  }
  Config.oidc.redirect_uri = "https://admin-integration.valtio.io"
} else if (process.env.REACT_APP_ENV_NAME === 'dev') {
  Config.valtioAPIEndpoint = 'http://manticore:8000'
  Config.valtioOrganizationId = "663f0b39e51b469e8b10bb6a931456de"
  Config.graphql = {
    endpoint: "https://uo2msrhltvc3lc7ugihqpqsz6e.appsync-api.us-east-2.amazonaws.com/graphql",
    region: "us-east-2",
    apiKey: "da2-uxhmpm7eu5btjl4dl3y66rv76a",
    channel: "Admin"
  }
  Config.oidc.redirect_uri = "http://localhost:3030"
}

export default Config;
