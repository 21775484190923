import React from "react";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumb,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "@themesberg/react-bootstrap";
import {
  faHome,
  faUpload,
  faSave,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";
import { useUpload } from "../../hooks/upload";
import { useRxDocument, useRxCollection } from "../../hooks/rxdb";
import { useAuth } from "react-oidc-context";
import DocuSealEditor from "../../components/DocuSealEditor";
import Dropzone from "../../components/Dropzone";
import Progress from "../../components/Progress";
import { PDFFormForm } from "../../components/Forms";
import { BeakerIcon } from "@heroicons/react/solid";
import { Routes } from "../../routes";
import Config from "../../config";

const STAGES = {
  IDLE: "Idle",
  UPLOADING: "Uploading",
  TAGGING: "Tagging",
  SAVING: "Saving",
  DONE: "Done",
};

export default () => {
  const { form_id } = useParams();
  const { upload } = useUpload();
  const auth = useAuth();
  const formRef = React.useRef();
  const history = useHistory();
  const collection = useRxCollection("forms");
  const [showTagEditor, setShowTagEditor] = React.useState(false);
  const [showUpload, setShowUpload] = React.useState(false);
  const [showSaving, setShowSaving] = React.useState(false);
  const [uploadStage, setUploadStage] = React.useState(STAGES.IDLE);
  const [uploadProgress, setUploadProcess] = React.useState(0);
  const { result: form, isFetching } = useRxDocument("forms", form_id);

  const onUpload = () => {
    setShowUpload(true);
    setShowTagEditor(false);
  };

  const onCancel = () => {
    setShowUpload(false);
    setShowTagEditor(true);
  };

  const onDrop = React.useCallback(
    async (file) => {
      setShowSaving(true);

      try {
        // generate an S3 pre-signed URL for the upload
        setUploadStage(STAGES.UPLOADING);
        setUploadProcess(20);
        const preSignedUrlResponse = await fetch(
          `${Config.valtioAPIEndpoint}/v1/forms/${form.id}/upload_link`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.user?.access_token}`,
            },
          }
        );

        // upload the file to S3 via a pre-signed URL
        setUploadStage(STAGES.UPLOADING);
        setUploadProcess(40);
        const uploadUri = await preSignedUrlResponse.json();
        await upload(file, uploadUri);

        // sync the pdf to the esignature provider
        setUploadStage(STAGES.TAGGING);
        setUploadProcess(60);
        const esignatureSyncResponse = await fetch(
          `${Config.valtioAPIEndpoint}/v1/forms/${form.id}/esignature_sync?autotag=true`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.user?.access_token}`,
            },
          }
        );

        // save the updated form the local stage
        setUploadStage(STAGES.SAVING);
        setUploadProcess(80);
        await new Promise((r) => setTimeout(r, 1000));
        const syncedForm = await esignatureSyncResponse.json();
        await form.patch(syncedForm);

        // Clear the Dropzone and show the form instead
        setShowUpload(false);
        setUploadStage(STAGES.DONE);
        setUploadProcess(100);
      } finally {
        setUploadStage(STAGES.IDLE);
        setShowSaving(false);
      }
    },
    [form, auth]
  );

  const onSave = React.useCallback(
    async (values) => {
      if (form) {
        await form.patch(values);
      } else {
        let form = await collection.insert({
          id: uuidv4().replaceAll("-", ""),
          ...values,
        });
        history.push(Routes.EditPDFForm.path.replace(":form_id", form.id));
      }
    },
    [form, collection]
  );

  const onDelete = React.useCallback(async () => {
    if (
      window.confirm(`Are you sure you want to delete form '${form.name}'?`)
    ) {
      await form.remove();
      history.push(Routes.Forms.path);
    }
  }, [form]);

  React.useEffect(() => {
    if (form) {
      setShowTagEditor(!!form.template_id);
      setShowUpload(!form.template_id);
    } else {
      setShowTagEditor(false);
      setShowUpload(false);
    }
  }, [form]);

  if (isFetching) {
    return "loading...";
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Valtio</Breadcrumb.Item>
            <Breadcrumb.Item active>Forms</Breadcrumb.Item>
            <Breadcrumb.Item active>Editor</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Form Editor</h4>
          <p className="mb-0">Manage Valtio Forms and edit PDF input fields.</p>
        </div>
        <div className="mb-3 d-flex justify-content-end">
          {form && (
            <Button variant="danger" onClick={onDelete} className="mx-2">
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </Button>
          )}
          {form && form.template_id && (
            <OverlayTrigger
              overlay={
                <Tooltip id="top" className="m-0">
                  Replaces the current PDF
                </Tooltip>
              }
            >
              <Button variant="primary" onClick={onUpload} className="mx-2">
                <FontAwesomeIcon icon={faUpload} /> Upload
              </Button>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            overlay={
              <Tooltip id="top" className="m-0">
                Save form details
              </Tooltip>
            }
          >
            <Button
              variant="secondary"
              onClick={() => {
                formRef.current.submit();
              }}
              className="mx-2"
            >
              <FontAwesomeIcon icon={faSave} /> {form ? "Save" : "Create"}
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <div className="justify-content-between align-items-center">
        <PDFFormForm form={form} onSubmit={onSave} ref={formRef} />
        {showUpload && (
          <>
            {uploadStage === STAGES.IDLE && (
              <Dropzone
                onDrop={onDrop}
                onCancel={form && form.template_id ? onCancel : null}
              />
            )}
          </>
        )}
        {showTagEditor && <DocuSealEditor form={form} />}
      </div>
      <Modal
        centered
        show={showSaving}
        onHide={() => {}}
        className="modal-tertiary"
      >
        <Modal.Header className="mx-auto">
          <p className="lead mb-0 text-white">Hang tight!</p>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="py-3 px-5 text-center">
            <span className="modal-icon display-1">
              <BeakerIcon className="icon icon-l text-white" />
            </span>

            <Modal.Title className="text-white mb-3">
              <h3>We're saving your changes</h3>
            </Modal.Title>
            <p className="mb-4 text-white">
              Walt (our AI) is scanning the PDF for form fields...
            </p>
            <Progress
              variant="primary"
              label={uploadStage}
              value={uploadProgress}
              type="tooltip"
              size="xl"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
