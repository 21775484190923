import React from "react";
import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "rxdb-hooks";

// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import * as database from "./stores/database";

import Config from "./config";
import { AuthWrapper } from "./components/AuthWrapper";

const Root = () => {
  const [db, setDb] = React.useState();

  // Init the application
  React.useEffect(() => {
    async function load() {
      // Initialize database
      const db = await database.initialize();
      setDb(db);
    }

    load().then();
  }, []);

  if (!db) return null;

  return (
    <AuthProvider {...Config.oidc}>
      <Provider db={db}>
        <HashRouter>
          <ScrollToTop />
          <AuthWrapper db={db}>
            <App db={db} />
          </AuthWrapper>
        </HashRouter>
      </Provider>
    </AuthProvider>
  );
};

render(<Root />, document.getElementById("root"));
