/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribe = /* GraphQL */ `
  subscription Subscribe($channel: String!, $event_type: String!) {
    subscribe(channel: $channel, event_type: $event_type) {
      channel
      event_type
      event_data
      __typename
    }
  }
`;
