import { replicateRxCollection } from "rxdb/plugins/replication";
import { lastOfArray } from "rxdb";
import { Subject } from "rxjs";
import { Amplify } from "aws-amplify";
import { generateClient, CONNECTION_STATE_CHANGE } from "aws-amplify/api";
import { Hub } from "aws-amplify/utils";
import { User } from "oidc-client-ts";
import { subscribe } from "../graphql/subscriptions";
import Config from "../config";

function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${Config.oidc.authority}:${Config.oidc.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export const initialize = async (db) => {
  Amplify.configure({
    API: {
      GraphQL: {
        defaultAuthMode: "apiKey",
        endpoint: Config.graphql.endpoint,
        region: Config.graphql.region,
        apiKey: Config.graphql.apiKey,
      },
    },
  });
  const channel = Config.graphql.channel;
  const event_types = ["forms"];
  const amplifyClient = generateClient();
  const states = {};

  for (const event_type of event_types) {
    let subscription$;
    let replication$;
    const pullStream$ = new Subject();
    states[event_type] = {
      start: async () => {
        subscription$ = amplifyClient
          .graphql({
            query: subscribe,
            variables: { channel, event_type },
          })
          .subscribe({
            next: async ({ data }) => {
              // await replication$.awaitInSync();
              const events = JSON.parse(data.subscribe.event_data);
              return pullStream$.next(events);
            },
            error: (error) => {
              console.error(error);
            },
          });

        replication$ = await replicateRxCollection({
          collection: db[event_type],
          /**
           * An id for the replication to identify it
           * and so that RxDB is able to resume the replication on app reload.
           * If you replicate with a remote server, it is recommended to put the
           * server url into the replicationIdentifier.
           */
          replicationIdentifier: event_type,
          /**
           * By default it will do an ongoing realtime replication.
           * By settings live: false the replication will run once until the local state
           * is in sync with the remote state, then it will cancel itself.
           * (optional), default is true.
           */
          live: true,
          /**
           * Time in milliseconds after when a failed backend request
           * has to be retried.
           * This time will be skipped if a offline->online switch is detected
           * via navigator.onLine
           * (optional), default is 5 seconds.
           */
          retryTime: 5 * 1000,
          /**
           * When multiInstance is true, like when you use RxDB in multiple browser tabs,
           * the replication should always run in only one of the open browser tabs.
           * If waitForLeadership is true, it will wait until the current instance is leader.
           * If waitForLeadership is false, it will start replicating, even if it is not leader.
           * [default=true]
           */
          waitForLeadership: true,
          /**
           * If this is set to false,
           * the replication will not start automatically
           * but will wait for replicationState.start() being called.
           * (optional), default is true
           */
          autoStart: true,
          /**
           * Custom deleted field, the boolean property of the document data that
           * marks a document as being deleted.
           * If your backend uses a different fieldname then '_deleted', set the fieldname here.
           * RxDB will still store the documents internally with '_deleted', setting this field
           * only maps the data on the data layer.
           *
           * If a custom deleted field contains a non-boolean value, the deleted state
           * of the documents depends on if the value is truthy or not. So instead of providing a boolean * * deleted value, you could also work with using a 'deletedAt' timestamp instead.
           *
           * [default='_deleted']
           */
          deletedField: "deleted",
          /**
           * Optional,
           * only needed when you want to replicate local changes to the remote instance.
           */
          push: {
            /**
             * Push handler
             */
            handler: async (docs) => {
              const user = getUser();
              const data = docs.map((doc) => ({
                last_update_date: doc.assumedMasterState
                  ? doc.assumedMasterState.updated_date
                  : null,
                document: doc.newDocumentState,
              }));
              // return [];
              /**
               * Push the local documents to a remote REST server.
               */
              const rawResponse = await fetch(
                `${Config.valtioAPIEndpoint}/v1/${event_type}/b`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.access_token}`,
                  },
                  body: JSON.stringify(data),
                }
              );

              const response = await rawResponse.json();

              // HTTP 422 - Unprocessable Entity
              if (rawResponse.status === 422) {
                // We need to drop unprocessable entries because retrying them will not help
                return [];
              } else return response;
            },
            /**
             * Batch size, optional
             * Defines how many documents will be given to the push handler at once.
             */
            batchSize: 5,
            /**
             * Modifies all documents before they are given to the push handler.
             * Can be used to swap out a custom deleted flag instead of the '_deleted' field.
             * If the push modifier return null, the document will be skipped and not send to the remote.
             * Notice that the modifier can be called multiple times and should not contain any side effects.
             * (optional)
             */
            modifier: (d) => {
              return d;
            },
          },
          /**
           * Optional,
           * only needed when you want to replicate remote changes to the local state.
           */
          pull: {
            /**
             * Pull handler
             */
            handler: async (lastCheckpoint, batchSize) => {
              const user = getUser();
              const lastIdFilter = lastCheckpoint
                ? `&last_id=${lastCheckpoint.id}`
                : "";
              const updatedAtFilter = lastCheckpoint
                ? `&updated_after=${lastCheckpoint.updated_date}`
                : "";
              /**
               * In this example we replicate with a remote REST server
               */
              const response = await fetch(
                `${Config.valtioAPIEndpoint}/v1/${event_type}/s/?limit=${batchSize}&sort_order=checkpoint${updatedAtFilter}${lastIdFilter}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${user?.access_token}`,
                  },
                }
              );
              const documents = (await response.json()).result;
              const checkpoint =
                documents.length === 0
                  ? lastCheckpoint
                  : {
                      id: lastOfArray(documents).id,
                      updated_date: lastOfArray(documents).updated_date,
                    };
              return {
                /**
                 * Contains the pulled documents from the remote.
                 * Notice: If documents.length < batchSize,
                 * then RxDB assumes that there are no more un-replicated documents
                 * on the backend, so the replication will switch to 'Event observation' mode.
                 */
                documents,
                /**
                 * The last checkpoint of the returned documents.
                 * On the next call to the pull handler,
                 * this checkpoint will be passed as 'lastCheckpoint'
                 */
                checkpoint,
              };
            },
            batchSize: 10,
            /**
             * Modifies all documents after they have been pulled
             * but before they are used by RxDB.
             * Notice that the modifier can be called multiple times and should not contain any side effects.
             * (optional)
             */
            modifier: (d) => {
              return d;
            },
            /**
             * Stream of the backend document writes.
             * See below.
             * You only need a stream$ when you have set live=true
             */
            stream$: pullStream$.asObservable(),
          },
        });
        await replication$.error$.subscribe((error) => {
          console.dir(error);
        });
      },
      stop: async () => {
        if (subscription$) await subscription$.unsubscribe();
        if (replication$ && !replication$.isStopped()) replication$.cancel();
      },
    };
  }

  Hub.listen("api", (data) => {
    const { payload } = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
      // if (
      //   priorConnectionState === ConnectionState.Connecting &&
      //   payload.data.connectionState === ConnectionState.Connected
      // ) {
      //   replicationState.reSync();
      // }
      // priorConnectionState = payload.data.connectionState;
    }
  });

  return {
    start: () => {
      for (const event_type in states) {
        states[event_type].start();
      }
    },
    stop: () => {
      for (const event_type in states) {
        states[event_type].stop();
      }
    },
  };
};
