import { useRxData } from "rxdb-hooks";

export { useRxDB, useRxData, useRxQuery, useRxCollection } from "rxdb-hooks";

export function useRxDocument(collectionName, id) {
  const { result, isFetching } = useRxData(collectionName, (collection) =>
    collection.findByIds([id])
  );

  return {
    result: result.length ? result[0] : null,
    isFetching,
  };
}
